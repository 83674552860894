<template>
  <div class="card card-custom gutter-b">
    <div class="card-header flex-wrap py-3">
      <div class="card-title">
        <h3 class="card-label">
          Check Items
          <span class="d-block text-muted pt-2 font-size-sm"
            >Add new fields or change the types of existing ones.</span
          >
        </h3>
      </div>
      <div class="card-toolbar">
        <!--begin::Button-->
        <edit_dialog />
        <!--end::Button-->
      </div>
    </div>
    <div class="card-body">
      <div
        id="kt_datatable_wrapper"
        class="dataTables_wrapper dt-bootstrap4 no-footer"
      >
        <div class="row">
          <div class="col-sm-12">
            <v-data-table
              dense
              :headers="headers"
              :items="items"
              :options.sync="options"
              :loading="loading"
              :search="search"
              :item-class="customRowClass"
              class="table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer"
            >
              <template v-slot:top>
                <div class="d-flex align-items-center position-relative my-1">
                  <span class="svg-icon svg-icon-1 position-absolute ms-6">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <rect
                        opacity="0.5"
                        x="17.0365"
                        y="15.1223"
                        width="8.15546"
                        height="2"
                        rx="1"
                        transform="rotate(45 17.0365 15.1223)"
                        fill="black"
                      ></rect>
                      <path
                        d="M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19
                              11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333
                              17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z"
                        fill="black"
                      ></path>
                    </svg>
                  </span>
                  <input
                    type="text"
                    v-model="search"
                    placeholder="Search in items"
                    label="Search"
                    class="form-control form-control-solid w-250px ps-14"
                  />
                </div>
              </template>
              <template v-slot:item.position="{ item }">
                <div v-if="item.position === 100">
                  More then 100
                </div>
                <div class="badge badge-success" v-else>
                  {{ item.position }}
                </div>
              </template>
              <template v-slot:item.actions="{ item }">
                <b-dropdown text="Actions">
                  <b-dropdown-item @click.prevent="editItem(item)">
                    Edit
                  </b-dropdown-item>
                  <b-dropdown-item @click.prevent="deleteItem(item)">
                    Delete
                  </b-dropdown-item>
                </b-dropdown>
              </template>
              <template v-slot:no-data>
                <v-btn color="primary" @click="initialize">
                  Reset
                </v-btn>
              </template>
            </v-data-table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import edit_dialog from "./edit";
import { mapGetters, mapState } from "vuex";
import Swal from "sweetalert2";
import {
  ENDPOINT_DELETE,
  ENDPOINT_GET,
  ENDPOINT_LIST
} from "@/core/services/store/check_items.module";

export default {
  components: { edit_dialog },
  data() {
    return {
      search: "",
      options: {},
      headers: [
        { text: "Catalog Term", value: "query" },
        { text: "Item", value: "item_name" },
        { text: "Position", value: "position" },
        { text: "Actions", value: "actions", sortable: false }
      ]
    };
  },
  watch: {
    changeProject() {
      this.reloadData();
    }
  },
  computed: {
    changeProject() {
      return this.currentProject.token;
    },
    ...mapState({
      errors: state => state.check_items.errors,
      message: state => state.check_items.message,
      hasMessage: state => state.check_items.hasMessage,
      items: state => state.check_items.list,
      loading: state => state.check_items.loading
    }),
    ...mapGetters(["currentProject"])
  },
  methods: {
    editItem(item) {
      this.$store.dispatch(ENDPOINT_GET, item.id);
    },
    customRowClass(item) {
      return item.alert_above < item.position ? "table-warning" : "";
    },
    deleteItem(item) {
      Swal.fire({
        title: "Are you sure?",
        text: `You are want delete \`${item.item_name}\`. Are you sure?`,
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!"
      }).then(result => {
        if (result.value) {
          this.$store.dispatch(ENDPOINT_DELETE, item.id).then(() => {
            this.reloadData();
            Swal.fire("Deleted!", "", "success");
          });
        }
      });
    },
    reloadData() {
      this.$store.dispatch(ENDPOINT_LIST);
    }
  },
  mounted() {
    this.reloadData();
  }
};
</script>
