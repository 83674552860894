<template>
  <v-dialog v-model="showDialog" persistent max-width="600px">
    <template v-slot:activator="{ on }">
      <a
        v-on="on"
        @click="refresh"
        href="#"
        class="btn btn-primary font-weight-bolder"
      >
        New Record</a
      >
    </template>
    <v-card>
      <v-form ref="form" v-model="valid" lazy-validation>
        <v-card-title>
          <span class="headline">Add check item</span>
          <v-spacer></v-spacer>
          <div class="card-toolbar">
            <!--begin::Button-->

            <v-flex class="mr-2">
              <collection_select />
            </v-flex>
            <!--end::Button-->
          </div>
        </v-card-title>

        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <div
                  role="alert"
                  v-bind:class="{ show: errors.length }"
                  class="alert fade alert-danger"
                >
                  <div class="alert-text" v-for="(error, i) in errors" :key="i">
                    {{ error }}
                  </div>
                </div>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  required
                  v-model="check_item.query"
                  label="Term"
                  ref="f_term"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-autocomplete
                  :loading="documents.length === 0"
                  v-model="check_item.elastic_item_id"
                  label="Item"
                  ref="f_elastic_item_id"
                  item-value="_id"
                  item-text="name"
                  :items="documents"
                  required
                ></v-autocomplete>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  required
                  v-model="check_item.alert_above"
                  label="Alert Below"
                  ref="f_alert_below"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
          <small>*indicates required field</small>
        </v-card-text>
        <v-card-actions>
          <v-btn color="darken-1" text @click="closeDialog">Close</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="primary dark large" ref="submit" @click="save"
            >Save
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters, mapState } from "vuex";

import Swal from "sweetalert2";
import {
  ENDPOINT_LIST,
  ENDPOINT_POST,
  ENDPOINT_PUT,
  REFRESH
} from "@/core/services/store/check_items.module";
import { PULL_DOCUMENTS } from "@/core/services/store/documents.module";
import collection_select from "@/view/pages/collections/collection_select";

export default {
  components: { collection_select },

  data: () => ({
    addDialog: false,
    valid: true
  }),
  methods: {
    reloadData() {
      if (this.selectedCollection && this.selectedCollection.id > 0) {
        this.$store.dispatch(PULL_DOCUMENTS, this.selectedCollection);
      }
    },
    refresh: function() {
      this.reloadData();
      this.addDialog = true;
      this.$store.dispatch(REFRESH);
    },
    save() {
      if (this.$refs.form.validate()) {
        if (this.check_item.id > 0) {
          this.$store
            .dispatch(ENDPOINT_PUT, {
              id: this.check_item.id,
              data: {
                query: this.check_item.query,
                elastic_item_id: this.check_item.elastic_item_id,
                alert_above: this.check_item.alert_above
              }
            })
            .then(() => {
              this.dialog = false;
              this.$store.dispatch(REFRESH);
              this.$store.dispatch(ENDPOINT_LIST);
              Swal.fire("Updated!", "success");
            });
        } else {
          this.$store
            .dispatch(ENDPOINT_POST, {
              query: this.check_item.query,
              elastic_item_id: this.check_item.elastic_item_id,
              alert_above: this.check_item.alert_above
            })
            .then(() => {
              this.dialog = false;
              this.$store.dispatch(REFRESH);
              this.$store.dispatch(ENDPOINT_LIST);
              Swal.fire("Added!", "success");
            });
        }
      }
    },
    closeDialog() {
      this.addDialog = false;
      this.$store.dispatch(REFRESH);
    }
  },
  watch: {
    changeProject() {
      this.reloadData();
    },
    changeCollection() {
      this.reloadData();
    }
  },
  computed: {
    showDialog() {
      return this.dialog || this.addDialog;
    },
    changeProject() {
      return this.currentProject.token;
    },
    changeCollection() {
      return this.selectedCollection.id;
    },
    ...mapState({
      errors: state => state.check_items.errors,
      message: state => state.check_items.message,
      documents: state => state.documents.documents,
      check_item: state => state.check_items.model,
      hasMessage: state => state.check_items.hasMessage,
      dialog: state => state.check_items.dialog
    }),
    ...mapGetters(["selectedCollection", "currentProject"])
  },
  beforeCreate() {
    this.$store.dispatch(REFRESH);
  },
  mounted() {
    this.reloadData();
  }
};
</script>
